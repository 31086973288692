body{
    margin: 0;
}
#root {
  width: 100vw;
  height: 100%;
}


.bp4-navbar.bp4-dark, .bp4-dark .bp4-navbar, .bp4-dark .bp4-html-select select:hover, .bp4-dark .bp4-select select:hover,.bp4-dark .bp4-html-select option, .bp4-dark .bp4-select option,.bp4-dark .bp4-html-select select, .bp4-dark .bp4-select select,.bp4-dark .bp4-button:not([class*="bp4-intent-"]),.bp4-dark .bp4-menu {
	background-color: #333;
}
.bp4-dark .polotno-side-panel path {
    fill: #fff !important;
  }
  
  /* overwrite colors for side-panel tabs */
  .bp4-dark .polotno-side-tabs-container .polotno-side-panel-tab:hover, .bp4-dark .polotno-side-tabs-container .polotno-side-panel-tab.active {
    background-color: #8842f0 !important;
    color: white !important;
    
  }
  .bp4-dark .polotno-side-tabs-inner{
    height: 100%;
justify-content: space-between;
   }
  /* change size of side panel tabs */
  .bp4-dark .polotno-side-tabs-container .polotno-side-panel-tab {
    width: 130px;
  }
  
  /* remove border radius from ImagesGrid */
  /* TODO: should polotno have better selectors? */
  .polotno-panel-container div[data-shadowenabled="true"] {
    border-radius: 0px;
  }
  
  /* change buttons in side panel */
  .bp4-dark .polotno-side-panel .bp4-button:not([class*=bp4-intent-]) {
    background-color: #868789;
  }
  
  /* change hover style of all buttons */
  .bp4-dark .bp4-button:not([class*=bp4-intent-]):hover {
    background-color: #8842f0 !important;
  }
  
  /* change colors of zoom buttons */
  .bp4-dark .zoom-buttons .bp4-button.bp4-minimal  {
    color: rgb(57, 57, 57) !important;
  }
  .bp4-dark .zoom-buttons .bp4-button:not([class*=bp4-intent-]) .bp4-icon:not([class*=bp4-intent-]) {
    color: rgb(57, 57, 57) !important;
  }

  .bp4-dark .go1599247145 path {
    fill: #222!important;
  }

  #logobutton{
    align-self: center;
    justify-self: center;
    background: url("./logo.svg") no-repeat center center;
    mask-image: url("./logo.svg") 
  }
  #logobutton:hover{
    background: url("./logo-w.svg") no-repeat center center;
  }
  .topbar{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .ds {
  position: absolute;
  width: 200px;
  height: 30px;
  background: #e7e7e7;
  bottom: 0;
  right: 0;
  z-index: 2;
  }

.private-panel{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.private-panel img{
  width: 8rem;
  align-self: center;
}
.private-panel p{
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 2rem;
  text-align: center;
  line-height: 2;
}
.private-panel input{
  width: 100%;
  height: 2rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0.5rem 0;
}
.private-panel .contact{
  font-size: 1rem;
}
  
  @media screen and (max-width: 500px){
    .bp4-dark .polotno-side-tabs-container .polotno-side-panel-tab {
      width: 72px;
    }
    .ds {display: none;    }

    .polotno-side-tabs-container {
      box-shadow: 0 -50px 0px 0 rgba(231,231,231,1);
      -webkit-box-shadow: 0 -50px 0px 0 rgba(231,231,231,1);
      -moz-box-shadow: 0 -50px 0px 0 rgba(231,231,231,1);
    }
    .polotno-panel-container{
      height: 65vh!important;
    }
  }

  /* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    html {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
    }
  } */

  .dwbutton{
    box-shadow: none!important;
background: transparent;
  }

  @media screen and (max-width: 500px){
    .go242266285::before {
      content: "";
      height: 20px;
      width: 20vw;
      background: url('./arrow.svg') center center no-repeat #333;
      background-size: 18px;
      display: block;
      position: absolute;
      left: Calc(50% - 10vw);
      top: -20px;
      border-radius: 50px 50px 0px 0px ;
      pointer-events: none;
    }
  
  }

  input:active, input:focus,button:active, button:focus, select:active, select:focus {
    outline: none;
  }